









import { Component, Model, Prop, Vue } from "vue-property-decorator";
import { CountryProvinceCityDto } from "@/components/CountryProvinceCity.vue";
import CountryProvinceCity from "@/components/CountryProvinceCity.vue";

@Component({
  name: "CountryProvinceCityControl",
  components: {
    CountryProvinceCity,
  },
})
export default class CountryProvinceCityControl extends Vue {
  @Model("change", { required: false, default: {} })
  modelValue!: CountryProvinceCityDto;

  @Prop({ required: false, default: false })
  hiddenCity!: boolean;
}
