














































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CountryProvinceCityControl from "@/components/CountryProvinceCityControl.vue";
import { CustomerBillAddressMappingCreateOrUpdateDto } from "@/api/appService";
import { CountryProvinceCityDto } from "@/components/CountryProvinceCity.vue";

@Component({
  name: "BillAddressItem",
  components: {
    CountryProvinceCityControl,
  },
})
export default class BillAddressItem extends Vue {
  @Prop({ required: true })
  index!: number;

  @Prop({ required: false, default: {} })
  billAddress!: CustomerBillAddressMappingCreateOrUpdateDto;

  region: CountryProvinceCityDto = {};
  item: CustomerBillAddressMappingCreateOrUpdateDto = {};

  @Watch("billAddress", { deep: true, immediate: true })
  billAddressChange(val: CustomerBillAddressMappingCreateOrUpdateDto) {
    this.item = { ...this.billAddress };
    this.region = {
      countryId: this.billAddress.countryId,
      provinceId: this.billAddress.provinceId,
    };
  }

  @Watch("region", { deep: true, immediate: true })
  regionChange(val: CountryProvinceCityDto) {
    if (val) {
      this.item.countryId = val.countryId;
      this.item.provinceId = val.provinceId;
    } else {
      this.item.countryId = undefined;
      this.item.provinceId = undefined;
    }
  }

  @Watch("item", { deep: true, immediate: true })
  itemChange(val: CustomerBillAddressMappingCreateOrUpdateDto) {
    this.$emit("update", this.index, val);
  }

  handleDeleteBillAddress() {
    this.$emit("delete", this.index);
  }

  checkBillAddressIsDefault() {
    this.$emit("check-default", this.index);
  }
}
