













































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CountryProvinceCityControl from "@/components/CountryProvinceCityControl.vue";
import { CustomerReceivingAddressMappingCreateOrUpdateDto } from "@/api/appService";
import { CountryProvinceCityDto } from "@/components/CountryProvinceCity.vue";

@Component({
  name: "ReceivingAddressItem",
  components: {
    CountryProvinceCityControl,
  },
})
export default class ReceivingAddressItem extends Vue {
  @Prop({ required: true })
  index!: number;

  @Prop({ required: false, default: {} })
  receivingAddress!: CustomerReceivingAddressMappingCreateOrUpdateDto;

  region: CountryProvinceCityDto = {};
  item: CustomerReceivingAddressMappingCreateOrUpdateDto = {};

  @Watch("receivingAddress", { deep: true, immediate: true })
  receivingAddressChange(
    val: CustomerReceivingAddressMappingCreateOrUpdateDto
  ) {
    this.item = { ...this.receivingAddress };
    this.region = {
      countryId: this.receivingAddress.countryId,
      provinceId: this.receivingAddress.provinceId,
    };
  }

  @Watch("region", { deep: true, immediate: true })
  regionChange(val: CountryProvinceCityDto) {
    if (val) {
      this.item.countryId = val.countryId;
      this.item.provinceId = val.provinceId;
    } else {
      this.item.countryId = undefined;
      this.item.provinceId = undefined;
    }
  }

  @Watch("item", { deep: true, immediate: true })
  itemChange(val: CustomerReceivingAddressMappingCreateOrUpdateDto) {
    this.$emit("update", this.index, val);
  }

  handleDeleteReceivingAddress() {
    this.$emit("delete", this.index);
  }

  checkReceivingAddressIsDefault() {
    this.$emit("check-default", this.index);
  }
}
