

































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CityDto, CountryDto, ProvinceDto } from "@/api/appService";
import api from "@/api";

export interface CountryProvinceCityDto {
  countryId?: number;
  provinceId?: number;
  cityId?: number;
}

@Component({
  name: "CountryProvinceCity",
})
export default class CountryProvinceCity extends Vue {
  @Prop({ required: false })
  country?: number;

  @Prop({ required: false })
  province?: number;

  @Prop({ required: false })
  city?: number;

  @Prop({ required: false, default: false })
  hiddenCity?: boolean;

  form: CountryProvinceCityDto = {};
  countryList: CountryDto[] = [];
  provinceList: ProvinceDto[] = [];
  cityList: CityDto[] = [];

  created() {
    this.init();
    this.getCountryList();
  }

  @Watch("form", { deep: true, immediate: true })
  changeForm(val: CountryProvinceCityDto) {
    this.updateData();
  }

  @Watch("country", { deep: true, immediate: true })
  changeCountry(val: number | undefined) {
    this.init();
    this.getProvinceList();
  }

  @Watch("province", { deep: true, immediate: true })
  changeProvince(val: number | undefined) {
    this.init();
    if (!this.hiddenCity) {
      this.getCityList();
    }
  }

  @Watch("city", { deep: true, immediate: true })
  changeCity(val: number | undefined) {
    this.init();
  }

  init() {
    this.form = {
      countryId: this.country,
      provinceId: this.province,
      cityId: this.city,
    };
  }

  getCountryList() {
    api.country.getAllList().then((res: CountryDto[]) => {
      this.countryList = res ?? [];
    });
  }

  getProvinceList() {
    if (this.form.countryId && this.form.countryId! > 0) {
      api.province
        .getAllList({ countryId: this.form.countryId })
        .then((res: ProvinceDto[]) => {
          this.provinceList = res ?? [];
          if (
            !this.provinceList ||
            !this.provinceList.find(
              (item: ProvinceDto) => item.id === this.form.provinceId
            )
          ) {
            this.form.provinceId = undefined;
          }
        });
    } else {
      this.form.provinceId = undefined;
      this.form.cityId = undefined;
      this.provinceList = [];
    }
  }

  getCityList() {
    if (!this.hiddenCity && this.form.provinceId && this.form.provinceId! > 0) {
      api.city
        .getAllList({ provinceId: this.form.provinceId })
        .then((res: CityDto[]) => {
          this.cityList = res ?? [];
          if (
            !this.cityList ||
            !this.cityList.find((item: CityDto) => item.id === this.form.cityId)
          ) {
            this.form.cityId = undefined;
          }
        });
    } else {
      this.form.cityId = undefined;
      this.cityList = [];
    }
  }

  updateData() {
    this.$emit("update:country", this.form.countryId);
    this.$emit("update:province", this.form.provinceId);
    if (!this.hiddenCity) {
      this.$emit("update:city", this.form.cityId);
    }
  }
}
