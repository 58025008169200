
































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import {
  CityDto,
  CustomerBillAddressMappingCreateOrUpdateDto,
  CustomerCreateOrUpdateDto,
  CustomerDto,
  CustomerReceivingAddressMappingCreateOrUpdateDto,
  ProvinceDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import { CountryProvinceCityDto } from "@/components/CountryProvinceCity.vue";
import CountryProvinceCityControl from "@/components/CountryProvinceCityControl.vue";
import BillAddressItem from "@/views/customer/enterprise/components/BillAddressItem.vue";
import ReceivingAddressItem from "@/views/customer/enterprise/components/ReceivingAddressItem.vue";

interface CustomerExtendCreateOrUpdateDto extends CustomerCreateOrUpdateDto {
  categoryArray?: number[] | undefined;
}

@Component({
  name: "EditEnterpriseCustomer",
  components: {
    ReceivingAddressItem,
    BillAddressItem,
    CountryProvinceCityControl,
  },
})
export default class EditEnterpriseCustomer extends Vue {
  @Ref() readonly dataForm!: ElForm;

  dataId = 0;
  show = false;
  loading = true;
  isConfirm = false;
  defaultData: CustomerDto = {
    id: 0,
  };

  form: CustomerExtendCreateOrUpdateDto = {
    mobilePhone: "",
    email: "",
    surname: "",
    name: "",
  };
  // categoryList: CustomerCategoryMappingCreateOrUpdateDto[] = [];
  // categorySelectList: CmsCategoryDto[] | undefined = [];
  receivingAddressSelectList:
    | CustomerReceivingAddressMappingCreateOrUpdateDto[]
    | undefined = [];
  billAddressSelectList:
    | CustomerBillAddressMappingCreateOrUpdateDto[]
    | undefined = [];

  receivingRegions: CountryProvinceCityDto[] = [];
  // categoryIds: number[] = [];
  linkRule = {
    enterpriseName: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "customer.tips.enterpriseName"
        ),
        trigger: "blur",
      },
    ],
    categoryArray: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "customer.tips.categoryArray"
        ),
        trigger: "change",
      },
    ],
    surname: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("customer.tips.surname"),
        trigger: "change",
      },
    ],
    name: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("customer.tips.name"),
        trigger: "blur",
      },
    ],
    mobilePhone: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("customer.tips.mobilePhone"),
        trigger: "blur",
      },
    ],
    email: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("customer.tips.email"),
        trigger: "blur",
      },
      {
        message: (this as any).$l.getLanguageText("customer.tips.emailFormat"),
        type: "email",
        trigger: "blur",
      },
    ],
  };

  get isDisable() {
    return this.dataId > 0;
  }

  get title() {
    if (this.dataId) {
      return (this as any).$l.getLanguageText("basic.edit");
    } else {
      return (this as any).$l.getLanguageText("basic.create");
    }
  }

  async save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      this.isConfirm = true;
      if (!valid) {
        return;
      }
      this.form.receivingAddressList = this.receivingAddressSelectList;
      this.form.billAddressList = this.billAddressSelectList;
      // let categorySelectList: CustomerCategoryMappingCreateOrUpdateDto[] = [];
      // console.log("categoryArray=" + JSON.stringify(this.form.categoryArray));
      // if (this.form.categoryArray) {
      //   this.form.categoryArray.forEach((item) => {
      //     categorySelectList.push({
      //       categoryId: Number(item),
      //     });
      //   });
      // }
      // this.form.categoryList = categorySelectList;

      // console.log("categoryList2=" + JSON.stringify(this.form.categoryList));

      if (this.dataId) {
        await api.customer
          .update({ body: this.form })
          .then(() => {
            this.isConfirm = false;
            this.$router.go(-1);
            this.$message.success(
              (this as any).$l.getLanguageText("basic.updateSuccess")
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await api.customer
          .create({ body: this.form })
          .then(() => {
            this.isConfirm = false;
            this.$router.go(-1);
            this.$message.success(
              (this as any).$l.getLanguageText("basic.createSuccess")
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    });
  }

  created() {
    this.fetchData();
    // this.getCategorySelectList();
  }

  onInput() {
    this.$forceUpdate();
  }

  fetchData() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.customer
        .get({ id: this.dataId })
        .then((res: CustomerDto) => {
          this.form = { ...this.form, ...res! };
          this.$set(this.form, "mobilePhone", res?.fkUser?.mobilePhone);
          this.$set(this.form, "email", res?.fkUser?.emailAddress);
          this.$set(this.form, "surname", res?.fkUser?.surname);
          this.$set(this.form, "name", res?.fkUser?.name);
          this.receivingAddressSelectList = res?.receivingAddressList;
          this.billAddressSelectList = res?.billAddressList;
          // res.categoryList?.forEach((item) => {
          //   this.categoryIds.push(Number(item.cmsCategoryId));
          // });
          // this.form.categoryArray = this.categoryIds;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.form = { ...this.defaultData };
      this.loading = false;
    }
  }

  // getCategorySelectList() {
  //   api.cmsCategory.getAll({ isNavigation: true }).then((res) => {
  //     this.categorySelectList = res?.items;
  //   });
  // }

  handleCreateReceivingAddress(
    item: CustomerReceivingAddressMappingCreateOrUpdateDto
  ) {
    this.receivingAddressSelectList!.push(item);
  }

  handleDeleteReceivingAddress(index: number) {
    this.receivingAddressSelectList!.splice(index, 1);
  }

  handleUpdateReceivingAddress(
    index: number,
    item: CustomerReceivingAddressMappingCreateOrUpdateDto
  ) {
    if (this.receivingAddressSelectList) {
      this.receivingAddressSelectList[index] = { ...item };
    }
  }

  handleCreateBillAddress(item: CustomerBillAddressMappingCreateOrUpdateDto) {
    if (this.form.enterpriseName) {
      item.contacts = this.form.enterpriseName;
    }
    this.billAddressSelectList!.push(item);
  }

  handleDeleteBillAddress(index: number) {
    this.billAddressSelectList!.splice(index, 1);
  }

  handleUpdateBillAddress(
    index: number,
    item: CustomerBillAddressMappingCreateOrUpdateDto
  ) {
    if (this.billAddressSelectList) {
      this.billAddressSelectList[index] = { ...item };
    }
  }

  checkReceivingAddressIsDefault(index: number) {
    // console.log("index=" + index);
    if (this.receivingAddressSelectList) {
      this.receivingAddressSelectList.forEach((item, id) => {
        item.isDefault = id == index;
      });
      this.$forceUpdate();
    }
  }

  checkBillAddressIsDefault(index: number) {
    if (this.billAddressSelectList) {
      this.billAddressSelectList.forEach((item, id) => {
        item.isDefault = id == index;
      });
      this.$forceUpdate();
    }
  }

  cancel() {
    this.$router.go(-1);
  }
}
